import { useApolloClient } from '@apollo/client';
import { getCachedQueryVariables } from '@aurora/shared-apollo/cacheQueryVariables';
import { ListItemSpacing, ListVariant } from '@aurora/shared-client/components/common/List/enums';
import List from '@aurora/shared-client/components/common/List/List';
import {
  PagerLoadMoreVariant,
  PagerPosition,
  PagerVariant
} from '@aurora/shared-client/components/common/Pager/enums';
import Pager from '@aurora/shared-client/components/common/Pager/Pager';
import PaginationHelper from '@aurora/shared-client/helpers/ui/PaginationHelper/PaginationHelper';
import { ConversationStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import type { Message, MessageEdge } from '@aurora/shared-generated/types/graphql-schema-types';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { ApolloQueryCacheKey, ItemType } from '../../../types/enums';
import type {
  MessageBoardFragment,
  MessageParentFragment,
  MessageRepliesQuery,
  MessageRepliesQueryVariables,
  MessageViewFragment,
  ThreadedReplyListSectionFragment
} from '../../../types/graphql-types';
import useEntityViewQuery from '../../useEntityViewQuery';
import { messageListMenuItemMap } from '../MessageListMenu/MessageListMenu';
import messageRepliesQuery from '../MessageReplies.query.graphql';
import MessageView from '../MessageView/MessageView';
import type { MessageList } from '../types';
import useMessageSort from '../useMessageSort';
import localStyles from './ThreadedReplyListSection.module.css';

interface Props extends MessageList {
  /**
   * The message for which to show the list of replies.
   */
  message: ThreadedReplyListSectionFragment & MessageBoardFragment;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * Newly posted message list.
   */
  newlyPostedMessageList?: Message[];
  /**
   * Updating newly posted message list on click of show more callback.
   * @param updatedList
   */
  updateListOnShowMore?: (updatedList: Message[]) => void;
}

/**
 * Display a list of replies for a message. The message is expected to have the
 * replies field already populated and will not run the query to get the replies
 * as part of the rendering of this component. If you need to render a list of replies
 * for a message where the replies are not already populated then use `ThreadedReplyList`.
 *
 * This component does not have a title, has no UI controls for sorting, and will be empty
 * when there are no replies.
 *
 * @constructor
 * @author Adam Ayres
 */
const ThreadedReplyListSection: React.FC<React.PropsWithChildren<Props>> = ({
  message,
  viewVariant,
  newlyPostedMessageList,
  updateListOnShowMore,
  listVariant = {
    type: ListVariant.UNSTYLED,
    props: {
      listItemClassName: (): string => (message?.depth <= 5 ? 'lia-g-ml-md-25 lia-g-ml-15' : ''),
      listItemSpacing: ListItemSpacing.SM
    }
  },
  pageSize = 10,
  pagerVariant = {
    type: PagerVariant.LOAD_MORE,
    props: {
      position: PagerPosition.START,
      clearLoadingAfterUpdate: true,
      variant: PagerLoadMoreVariant.BORDERED,
      className: 'lia-g-ml-md-25 lia-g-ml-15'
    }
  },
  className
}) => {
  const cx = useClassNameMapper(localStyles);
  const client = useApolloClient();
  const { sortMenuItem } = useMessageSort();

  const { sorts } = messageListMenuItemMap[sortMenuItem];

  const { id, replies, depth } = message;
  const pageInfo = replies?.pageInfo ?? { hasNextPage: false, hasPreviousPage: false };
  const replyMessages = replies?.edges?.map((item: MessageEdge) => item.node) ?? [];
  const { conversationStyle } = message.board;
  const isIdea = conversationStyle === ConversationStyle.Idea;

  /**
   * These variables need to reflect the variables used by the parent query. The pageSize will
   * be changed accordingly by `PaginationHelper` when using `fetchMore` to get subsequent pages.
   */
  const isTopic = depth === 0;
  const cacheKey =
    (isTopic ? ApolloQueryCacheKey.TOPIC_REPLY_LIST : ApolloQueryCacheKey.REPLY_LIST) +
    `:${id}:${message.revisionNum}`;
  const cachedVariables = getCachedQueryVariables<MessageRepliesQueryVariables>(client, cacheKey);

  function getFirst() {
    if (depth % 3 === 0) {
      return 10;
    } else if (depth % 3 === 1) {
      return 3;
    } else if (depth % 3 === 2) {
      return 1;
    }
    return 0;
  }

  function getRepliesFirst() {
    if (depth % 3 === 0) {
      return 3;
    } else if (depth % 3 === 1) {
      return 1;
    } else if (depth % 3 === 2) {
      return 1;
    }
    return 0;
  }

  const variables: MessageRepliesQueryVariables = {
    ...cachedVariables,
    id,
    first: getFirst(),
    repliesFirst: getRepliesFirst(),
    repliesFirstDepthThree: 1,
    sorts,
    repliesSorts: sorts,
    attachmentsFirst: 5,
    useReadOnlyIcon: false,
    useUserHoverCard: true,
    useAttachments: false,
    useMedia: false,
    useModerationStatus: true,
    useNodeAncestors: false,
    useNodeHoverCard: false,
    useMessageStatus: true,
    useRepliesCount: true,
    ...(isIdea && {
      constraints: {
        includeHiddenComments: {
          eq: false
        }
      }
    })
  };

  const { fetchMore } = useEntityViewQuery<
    ItemType.MESSAGE,
    MessageRepliesQuery,
    MessageRepliesQueryVariables
  >(module, ItemType.MESSAGE, viewVariant, messageRepliesQuery, {
    variables,
    skip: true
  });

  /**
   * renders the specified message.
   * @param entity message to render.
   */
  function renderMessage(entity: MessageViewFragment): React.ReactElement {
    return <MessageView entity={entity} variant={viewVariant} />;
  }

  const paginationHelper = new PaginationHelper<MessageRepliesQuery, MessageRepliesQueryVariables>(
    fetchMore,
    pageInfo,
    'message.replies',
    pageSize,
    variables,
    connection => {
      let newList: Message[] = [];
      if (newlyPostedMessageList?.length > 0 && connection?.edges?.length > 0) {
        newList = newlyPostedMessageList.filter(element => {
          const commonElement = connection.edges.find(
            reply => (reply as MessageEdge).node.id === element.id
          );
          if (!commonElement) {
            return element;
          }
          return null;
        });
      }
      if (newList.length !== newlyPostedMessageList.length) {
        updateListOnShowMore(newList);
      }
      return {
        message: {
          id: message.id,
          revisionNum: message.revisionNum,
          __typename: message.__typename,
          replies: connection,
          parent: (message as MessageParentFragment).parent ?? {},
          repliesCount: message.repliesCount
        }
      } as unknown as MessageRepliesQuery;
    },
    undefined,
    { message }
  );

  return (
    replyMessages.length > 0 && (
      <div className={cx(className)}>
        <List<MessageViewFragment>
          items={replyMessages}
          variant={listVariant}
          className={cx('lia-g-mt-5 lia-replies')}
        >
          {renderMessage}
        </List>
        <Pager
          pageInfo={paginationHelper.pageInfo}
          loadPage={paginationHelper.loadPage}
          variant={pagerVariant}
          className={cx('lia-pager')}
        />
      </div>
    )
  );
};

export default ThreadedReplyListSection;
