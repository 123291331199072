import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useIsomorphicRedirect, {
  RedirectStatusCode
} from '@aurora/shared-client/components/useIsomorphicRedirect';
import useIsomorphicRedirectByUrl from '@aurora/shared-client/components/useIsomorphicRedirectByUrl';
import type { MessagePageOrReplyPageAndParams } from '@aurora/shared-client/routes/endUserRoutes';
import { VisibilityScope } from '@aurora/shared-generated/types/graphql-schema-types';
import UrlBuilder from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlBuilder';
import { getLog } from '@aurora/shared-utils/log';
import { canUseDOM } from 'exenv';
import { useContext } from 'react';
import { EndUserPages, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import ActionFeedbacks from './common/ActionFeedback/ActionFeedbackTypes';
import UrlHelper from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import ConversationStyleBehaviorHelper from '../helpers/boards/ConversationStyleBehaviorHelper';
import type { TopicMessage } from './tags/MessageTagEditor/TagHelper';

const log = getLog(module);

/**
 *
 * If message is archived, Redirects to message's suggested url if present.
 *
 * @author Muttu K Sulikeri, Manasvini.Arul, BE.Abhijith
 */
export default function useRedirectOnArchivedMessage(): void {
  const { contextMessage, contextMessageArchivalData } = useContext(AppContext);
  const tenant = useContext(TenantContext);
  const { router } = useEndUserRoutes();
  const isArchivedMessage: boolean = contextMessage
    ? contextMessage?.visibilityScope === VisibilityScope.Archived
    : contextMessageArchivalData?.archived;
  const shouldStayOnArchivedPostPage =
    router.getUnwrappedQueryParam(EndUserQueryParams.ARCHIVED_CONTENT) === 'true';
  const suggestedUrl = isArchivedMessage
    ? (contextMessage as TopicMessage)?.archivalData?.suggestedUrl ??
      contextMessageArchivalData?.suggestedUrl
    : null;
  const shouldRedirect =
    !canUseDOM && isArchivedMessage && !shouldStayOnArchivedPostPage && !!suggestedUrl;
  let redirectRoute = {
    route: null,
    params: null,
    query: null
  };
  let finalRoute = null;
  if (suggestedUrl) {
    const urlDomain = new URL(suggestedUrl).origin;
    if (tenant.host === urlDomain) {
      finalRoute = suggestedUrl ? router.getRouteAndParamsByPath(suggestedUrl) : null;
      const { route, params, query } = finalRoute;
      let queryParams;
      if (!!contextMessage) {
        const isMessagePage = [
          EndUserPages.ForumMessagePage,
          EndUserPages.BlogMessagePage,
          EndUserPages.TkbMessagePage,
          EndUserPages.IdeaMessagePage,
          EndUserPages.OccasionMessagePage
        ].includes(route);
        const { messagePage } = ConversationStyleBehaviorHelper.getInstance(contextMessage.board);
        const relativeUrlForRoute = router.getRelativeUrlForRoute<MessagePageOrReplyPageAndParams>(
          messagePage,
          {
            boardId: contextMessage.board.displayId,
            messageSubject: UrlHelper.determineSlugForMessagePath(contextMessage),
            messageId: contextMessage.uid.toString()
          }
        );
        const archivedMessageUrl = UrlBuilder.fromUrl(tenant.baseUrl)
          .addPath(relativeUrlForRoute)
          .build();
        queryParams = !isMessagePage
          ? {
              ...query,
              [EndUserQueryParams.ACTION_FEEDBACK]: ActionFeedbacks.REDIRECT_TO_RELATED_PAGE,
              [EndUserQueryParams.TIMESTAMP]: Date.now().toString(),
              [EndUserQueryParams.ARCHIVED_MESSAGE_URL]: archivedMessageUrl
            }
          : {
              [EndUserQueryParams.ARCHIVED_MESSAGE_ID]: contextMessage.uid.toString()
            };
      } else {
        queryParams = {
          ...finalRoute?.query,
          [EndUserQueryParams.ACTION_FEEDBACK]:
            ActionFeedbacks.REDIRECT_TO_RELATED_PAGE_FOR_BASE_USERS,
          [EndUserQueryParams.TIMESTAMP]: Date.now().toString()
        };
      }

      redirectRoute = {
        route: route,
        params: params,
        query: queryParams
      };
    }
    log.debug("redirecting to archived message's suggested url:", suggestedUrl);
  }

  // Redirect to internal community urls will be handled here
  useIsomorphicRedirect(
    shouldRedirect && !!finalRoute?.route,
    redirectRoute,
    RedirectStatusCode.MOVED_PERMANENTLY,
    true
  );
  // Redirect to external urls will be handled here
  useIsomorphicRedirectByUrl(
    shouldRedirect && !finalRoute?.route,
    suggestedUrl,
    RedirectStatusCode.MOVED_PERMANENTLY,
    true
  );
}
